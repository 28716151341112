import React from "react";

const HealthRecordIcon: React.FC = () => <>
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.0117188" width="45.4964" height="45.4964" rx="8" fill="white"/>
  <rect x="-1" y="1" width="19.8004" height="23.8361" rx="1" transform="matrix(-1 0 0 1 31.6602 9.83594)" stroke="url(#paint0_linear_3864_27835)" stroke-width="2"/>
  <rect x="18.8477" y="15.3555" width="1.83196" height="5.49589" fill="url(#paint1_linear_3864_27835)"/>
  <rect x="17.0156" y="19.0195" width="1.83196" height="5.49589" transform="rotate(-90 17.0156 19.0195)" fill="url(#paint2_linear_3864_27835)"/>
  <defs>
  <linearGradient id="paint0_linear_3864_27835" x1="0" y1="0" x2="26.6554" y2="6.79736" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="0.0001" stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  <linearGradient id="paint1_linear_3864_27835" x1="18.8477" y1="15.3555" x2="21.2093" y2="15.5934" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="0.0001" stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  <linearGradient id="paint2_linear_3864_27835" x1="17.0156" y1="19.0195" x2="19.3773" y2="19.2574" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="0.0001" stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  </defs>
  </svg>
</>

export default HealthRecordIcon;
