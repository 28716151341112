import React, { useContext, useState, useEffect, useRef } from "react";

import { mod } from "../utils";
import { buttonStyle } from "../components/styles";
import { Base, LimitMaxWidth, ModalContext, SectionHeading } from "../components/layout";
import HealthAreasSection from "../components/page-sections/HealthAreasSection";
import WeServeSection from "../components/page-sections/WeServeSection";
import TestimonialsSection from "../components/page-sections/TestimonialsSection";
import CertificationsSection from "../components/page-sections/CertificationsSection";
import DemoAndEmployerBannerSection from "../components/page-sections/DemoAndEmployerBannerSection";
import DemoModal from "../components/modals/DemoModal";
import SEO from "../components/seo";

import HeartIconSvg from "../assets/svg/icon-heart";
import HealthRecordIconSvg from "../assets/svg/icon-health-record";
import PersonIconSvg from "../assets/svg/icon-person";

import app121Png from "../assets/images/app-121.png";
import appCarePlanPng from "../assets/images/app-care-plan.png";
import appHealthCoachPng from "../assets/images/app-health-coach.png";
import appOnDemandPng from "../assets/images/app-on-demand.png";
import appTestPng from "../assets/images/app-test.png";
import appTrackingPng from "../assets/images/app-tracking.png";
import heroPng from "../assets/images/hero-home.png";
import app121Avif from "../assets/images/app-121.avif";
import appCarePlanAvif from "../assets/images/app-care-plan.avif";
import appHealthCoachAvif from "../assets/images/app-health-coach.avif";
import appOnDemandAvif from "../assets/images/app-on-demand.avif";
import appTestAvif from "../assets/images/app-test.avif";
import appTrackingAvif from "../assets/images/app-tracking.avif";
import app121Webp from "../assets/images/app-121.webp";
import appCarePlanWebp from "../assets/images/app-care-plan.webp";
import appHealthCoachWebp from "../assets/images/app-health-coach.webp";
import appOnDemandWebp from "../assets/images/app-on-demand.webp";
import appTestWebp from "../assets/images/app-test.webp";
import appTrackingWebp from "../assets/images/app-tracking.webp";

import heroAvif from "../assets/images/hero-home.avif";
import heroWebp from "../assets/images/hero-home.webp";

import syronaVideoMp4 from "../assets/videos/syrona-video.mp4";
import heroVideoMp4 from "../assets/videos/hero-home.mp4";
import heroVideoSmallMp4 from "../assets/videos/hero-home-small.mp4";
import syronaVideoWebm from "../assets/videos/syrona-video.webm";
import heroVideoWebm from "../assets/videos/hero-home.webm";
import heroVideoSmallWebm from "../assets/videos/hero-home-small.webm";

const bottomBanner = <DemoAndEmployerBannerSection title="People are your asset." subtitle="So let's start a converstaion." showEmployerReferral={true} />

export const Head = () => <SEO
  title="Syrona Health | Gender-Inclusive Employee-Wellbeing Benefits"
  description="Get the SORA app from Syrona Health today!"
/>

const HomePage: React.FC = () => <Base bottomBanner={bottomBanner}><HomeContent/></Base>

export default HomePage;

const HomeContent: React.FC = () => <>
  <HeroSection />
  <HealthAreasSection backgroundClass="bg-syr-snd-lt" title="Serving diverse healthcare needs for every life stage" />
  <AppExamplesSection />
  <VideoSection />
  <WeServeSection />
  <TestimonialsSection />
  <CertificationsSection />
</>

const HeroSection: React.FC = () => {
  const { setModal } = useContext(ModalContext)
  return <>
    <div className="bg-cover bg-no-repeat bg-right-top relative overflow-hidden">
      <LimitMaxWidth>
        <div className="sm:flex sm:items-center py-24 mr-8">
          <div className="sm:order-0 sm:max-md:w-10/12 md:max-lg:w-6/12 lg:w-6/12 flex flex-col justify-center space-y-0">
            <h3 className="max-sm:text-m-lg-t sm:text-h2 font-dm text-white">
              Empower Your People
            </h3>
            <h4 className="max-sm:text-28 sm:text-38 font-dmi text-white">
              by supporting underserved health journeys.
            </h4>
            <p className="pt-2 pb-4 max-md:text-m-body md:text-b1 font-nun text-white">
              Each stage of life brings its own health challenges. We provide inclusive virtual health support for life's big or small journeys to empower your business and people.
            </p>
            <div>
              <button
                className={`${buttonStyle} bg-white text-black`}
                onClick={() => setModal(<DemoModal/>)}
              >
                Book a demo
              </button>
            </div>
          </div>
        </div>
      </LimitMaxWidth>
        <div className="absolute top-0 h-full w-full -z-20 object-cover">
          <picture>
            <source srcSet={heroAvif} type="image/avif" />
            <source srcSet={heroWebp} type="image/webp" />
            <img className="w-full h-full object-cover object-center" src={heroPng} alt=""/>
          </picture>
        </div>
        <div className="absolute top-0 md:hidden h-full w-full -z-10 object-cover">
          <video 
            className="w-full h-full object-cover object-center"
            playsInline
            muted
            autoPlay
            loop
          >
            <source src={heroVideoSmallWebm} type="video/webm"/>
            <source src={heroVideoSmallMp4} type="video/mp4"/>
          </video>
        </div>
        <div className="absolute top-0 max-md:hidden h-full w-full -z-10 object-cover">
          <video 
            className="w-full h-full object-cover object-center"
            playsInline
            muted
            autoPlay
            loop
          >
            <source src={heroVideoWebm} type="video/webm"/>
            <source src={heroVideoMp4} type="video/mp4"/>
          </video>
        </div>
    </div>
  </>
}


type AppExample = {
  title: string;
  description: string;
}
const appExamples: AppExample[] = [
  {
    title: "Health Tracking",
    description: "Log your symptoms and lifestyle to track your health.",
  },
  {
    title: "On-Demand Resources",
    description: "Access medically reviewed articles, videos and live events.",
  },
  {
    title: "Personalised Care Plans",
    description: "Your care is personalised for your top symptoms.",
  },
  {
    title: "Your Personal Health Coach",
    description: "Your very own health coach to chat 1-1.",
  },
  {
    title: "One-to-one Virtual Consultations",
    description: "Schedule virtual sessions with our health experts.",
  },
  {
    title: "Test Yourself",
    description: "With at-home diagnostic tests, testing just got easy!",
  },
]

const AppExamplesSection: React.FC = () => {
  const [example, setExample] = useState<number>(0);
  const [rotationTimeout, setRotationTimeout] = useState<NodeJS.Timeout|null>(null);
  const buttonAreaRef = useRef(null);

  useEffect(() => {
    rotationTimeout && clearTimeout(rotationTimeout)
    const timeout = setTimeout(() => setExample(mod(example+1, appExamples.length)), 10000);
    setRotationTimeout(timeout)
  }, [example])

  const posStyling = [
    {
      textColour: "text-black",
      bgColour: "bg-syr-snd-lt",
      borderColour: "border-syr-snd",
      borderWidth: "border-4",
      scale: "",
      marginY: "my-4"
    },
    {
      textColour: "text-gray-400",
      bgColour: "bg-white",
      borderColour: "border-gray-400",
      borderWidth: "border-2",
      scale: "md:scale-90",
      marginY: "my-1"
    },
    {
      textColour: "text-gray-300",
      bgColour: "bg-white",
      borderColour: "border-gray-300",
      borderWidth: "border-2",
      scale: "md:scale-75",
      marginY: ""
    },
  ]
  const appExampleText = (posIdx: number, exIdx: number) => { 
    const ae = appExamples[exIdx]
    return (
      <div className="flex flex-col justify-center max-md:px-2 max-md:space-y-2 md:px-10">
        <div className={`max-md:text-m-t2 md:text-h4 font-dm ${posStyling[Math.abs(posIdx)].textColour}`}>
          {ae.title}
        </div>
        <div className={`max-md:text-m-s md:text-b1 font-nun ${posStyling[Math.abs(posIdx)].textColour}`}>
          {ae.description}
        </div>
      </div>
    )
  }

  const appExampleButton = (posIdx: number, exIdx: number) => (
    <button
      className={`
        md:w-144
        max-md:w-60
        max-md:h-40
        max-md:flex-none
        ${posStyling[Math.abs(posIdx)].marginY}
        ${posStyling[Math.abs(posIdx)].scale}
        ${posStyling[Math.abs(posIdx)].bgColour}
        border-solid
        border-2
        ${posStyling[Math.abs(posIdx)].borderWidth}
        ${posStyling[Math.abs(posIdx)].borderColour}
        rounded-lg`}
      onClick={() => setExample(exIdx)}
    >
      <div className="text-left py-4">
        {appExampleText(posIdx, exIdx)}
      </div>
    </button>
  )

  useEffect(() => {
    if (buttonAreaRef.current) {
      buttonAreaRef.current.scrollLeft = (buttonAreaRef.current.scrollWidth - window.innerWidth) / 2;
    }
  }, [example])

  return <>
    <div className="bg-white py-12">
      <LimitMaxWidth>
        <SectionHeading>
          The expert support you deserve
        </SectionHeading>
        <div className="md:flex justify-center items-center">
          <div className="md:flex-initial md:w-1/2">
            <div className="flex justify-center items-center max-md:w-screen sm:max-md:-ml-16 max-sm:-ml-8">
              <div ref={buttonAreaRef} className="flex md:flex-col max-md:w-screen max-md:space-x-8 max-md:overflow-x-scroll object-center max-md:overscroll-none max-md:no-scrollbar items-center">
                {appExampleButton(-2, mod(example-2, appExamples.length))}
                {appExampleButton(-1, mod(example-1, appExamples.length))}
                {appExampleButton(0, example)}
                {appExampleButton(1, mod(example+1, appExamples.length))}
                {appExampleButton(2, mod(example+2, appExamples.length))}
              </div>
            </div>
          </div>
          <div className="md:flex-initial md:w-1/2">
            <div className="flex justify-center items-center p-8">
              <div className={example == 0 ? "" : "hidden"}>
                <picture>
                  <source srcSet={appTrackingAvif} type="image/avif" />
                  <source srcSet={appTrackingWebp} type="image/webp" />
                  <img className="max-h-144 object-scale-down" src={appTrackingPng} alt="Tracking"/>
                </picture>
              </div>
              <div className={example == 1 ? "" : "hidden"}>
                <picture>
                  <source srcSet={appOnDemandAvif} type="image/avif" />
                  <source srcSet={appOnDemandWebp} type="image/webp" />
                  <img className="max-h-144 object-scale-down" src={appOnDemandPng} alt="Resources"/>
                </picture>
              </div>
              <div className={example == 2 ? "" : "hidden"}>
                <picture>
                  <source srcSet={appCarePlanAvif} type="image/avif" />
                  <source srcSet={appCarePlanWebp} type="image/webp" />
                  <img className="max-h-144 object-scale-down" src={appCarePlanPng} alt="Care Plan"/>
                </picture>
              </div>
              <div className={example == 3 ? "" : "hidden"}>
                <picture>
                  <source srcSet={appHealthCoachAvif} type="image/avif" />
                  <source srcSet={appHealthCoachWebp} type="image/webp" />
                  <img className="max-h-144 object-scale-down" src={appHealthCoachPng} alt="Health Coach"/>
                </picture>
              </div>
              <div className={example == 4 ? "" : "hidden"}>
                <picture>
                  <source srcSet={app121Avif} type="image/avif" />
                  <source srcSet={app121Webp} type="image/webp" />
                  <img className="max-h-144 object-scale-down" src={app121Png} alt="Consultations"/>
                </picture>
              </div>
              <div className={example == 5 ? "" : "hidden"}>
                <picture>
                  <source srcSet={appTestAvif} type="image/avif" />
                  <source srcSet={appTestWebp} type="image/webp" />
                  <img className="max-h-144 object-scale-down" src={appTestPng} alt="Testing"/>
                </picture>
              </div>
            </div>
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const VideoSection: React.FC = () => <>
  <div className="py-12 bg-gradient-to-r from-syr-main to-syr-snd">
    <LimitMaxWidth>
      <div className="flex max-lg:flex-col justify-center max-lg:items-start">
        <video 
          className="
            lg:ml-8
            lg:order-1
            lg:w-7/12
            lg:h-96
            object-cover
            object-center
            rounded-2xl
          "
          controls
        >
          <source src={syronaVideoWebm} type="video/webm"/>
          <source src={syronaVideoMp4} type="video/mp4"/>
        </video>
        <div className="
          lg:order-0
          max-lg:mt-8
          lg:w-5/12
          lg:aspect-square
          lg:h-96
          lg:rounded-2xl
          lg:bg-white
          lg:bg-opacity-10
          flex
          flex-col
          justify-center
          lg:p-8
          space-y-4"
        >
          <div className="flex space-x-8">
            <div className="">
              <HeartIconSvg/>
            </div>
            <div className="">
              <PersonIconSvg/>
            </div>
            <div className="">
              <HealthRecordIconSvg/>
            </div>
          </div>
          <div>
            <h3 className="max-sm:text-m-t1 sm:text-h4 font-dm text-white">
            Healthcare isn't one-size-fits-all. Our solutions aren't either.
            </h3>
            <p className="max-sm:test-m b sm:text-b1 font-nun text-white">
              The combination of personalisation, technology and instant access to our clinical experts leads to transformative healthcare outcomes.
            </p>
          </div>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>
