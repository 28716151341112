import React, { useEffect, useState } from "react";

import { mod } from "../../utils";
import { LimitMaxWidth, SectionHeading } from "../layout";
import CaretLeftLg from "../../assets/svg/caret-left-lg";
import CaretRightLg from "../../assets/svg/caret-right-lg";
import endometriosisPng from "../../assets/images/health-area-endometriosis.png";
import fertilityPng from "../../assets/images/health-area-fertility.png";
import menopausePng from "../../assets/images/health-area-menopause.png";
import mensHealthPng from "../../assets/images/health-area-mens-health.png";
import parentSupportPng from "../../assets/images/health-area-parent-support.png";
import pcosPng from "../../assets/images/health-area-pcos.png";
import cancerPng from "../../assets/images/health-area-cancer.png";
import endometriosisAvif from "../../assets/images/health-area-endometriosis.avif";
import fertilityAvif from "../../assets/images/health-area-fertility.avif";
import menopauseAvif from "../../assets/images/health-area-menopause.avif";
import mensHealthAvif from "../../assets/images/health-area-mens-health.avif";
import parentSupportAvif from "../../assets/images/health-area-parent-support.avif";
import pcosAvif from "../../assets/images/health-area-pcos.avif";
import cancerAvif from "../../assets/images/health-area-cancer.avif";
import endometriosisWebp from "../../assets/images/health-area-endometriosis.webp";
import fertilityWebp from "../../assets/images/health-area-fertility.webp";
import menopauseWebp from "../../assets/images/health-area-menopause.webp";
import mensHealthWebp from "../../assets/images/health-area-mens-health.webp";
import parentSupportWebp from "../../assets/images/health-area-parent-support.webp";
import pcosWebp from "../../assets/images/health-area-pcos.webp";
import cancerWebp from "../../assets/images/health-area-cancer.webp";

type HealthArea = {
  avif: string;
  png: string;
  webp: string;
  button: string;
  title: string;
  subTitle?: string;
  text: string[];
  stat: string;
  statDescription: string;
}

const haOrdering = [
  "order-0",
  "order-1",
  "order-2",
  "order-3",
  "order-4",
  "order-5",
  "order-6",
]

const healthAreas: HealthArea[] = [
  {
    avif: fertilityAvif,
    png: fertilityPng,
    webp: fertilityWebp,
    button: "Fertility",
    title: "Fertility",
    subTitle: "Workplace impact",
    text: [
      "60% of employees have left or considered leaving a job due to inadequate family benefits. One third (36%) had to take increased sickness absence and two thirds (63%) of respondents reported reduced engagement at work.",
    ],
    stat: "1\u00A0in\u00A06",
    statDescription: "couples experience fertility challenges",
  },
  {
    avif: pcosAvif,
    png: pcosPng,
    webp: pcosWebp,
    button: "PCOS",
    title: "PCOS (Polycystic Ovary Syndrome)",
    subTitle: "Workplace impact",
    text: [
      "Women with PCOS often report having poor work ability due to their condition and are more likely to have disability-based absences than women without PCOS.",
    ],
    stat: "1\u00A0in\u00A010",
    statDescription: "women are affected by PCOS",
  },
  {
    avif: endometriosisAvif,
    png: endometriosisPng,
    webp: endometriosisWebp,
    button: "Endometriosis",
    title: "Endometriosis",
    subTitle: "Workplace impact",
    text: [
      "1 in 6 women with endometriosis give up work and 27% say they've missed out on a promotion because of their Endometriosis.",
    ],
    stat: "1\u00A0in\u00A010",
    statDescription: "women are affected by Endometriosis",
  },
  {
    avif: menopauseAvif,
    png: menopausePng,
    webp: menopauseWebp,
    button: "Menopause",
    title: "Menopause",
    subTitle: "Workplace impact",
    text: [
      "6 in 10 menopausal women say their symptoms have had a negative impact on their work and 1 in 4 menopausal women say they don't get the support they need from their manager.",
    ],
    stat: "75%",
    statDescription: "of women are affected by menopausal symptoms",
  },
  {
    avif: mensHealthAvif,
    png: mensHealthPng,
    webp: mensHealthWebp,
    button: "Men's Health",
    title: "Men's Health",
    subTitle: "Workplace impact",
    text: [
      "34% of men don't feel like they're able to be open about their mental health at work, and 1 in 8 men will be diagnosed with Prostate cancer.",
    ],
    stat: "75%",
    statDescription: "of premature deaths from heart disease occur in men",
  },
  {
    avif: parentSupportAvif,
    png: parentSupportPng,
    webp: parentSupportWebp,
    button: "Parent Support",
    title: "Parent Support",
    subTitle: "Workplace impact",
    text: [
      "Nearly 2 in 5 (38%) mothers and 1 in 7 (14%) fathers say that having kids has harmed their careers. In addition, 17% of women leave employment entirely in the five years following childbirth.",
    ],
    stat: "31%",
    statDescription: "of new parents feel unprepared for the realities of becoming a parent",
  },
  {
    avif: cancerAvif,
    png: cancerPng,
    webp: cancerWebp,
    button: "Cancer",
    title: "Cancer",
    subTitle: "Workplace impact",
    text: [
      "Up to 53% of people living with cancer experience job loss and unemployment can be 1.4 times more likely in people living with cancer than those without cancer.",
    ],
    stat: "1\u00A0in\u00A02",
    statDescription: "people will develop some form of cancer in their lifetime",
  },
]

type Props = {
  backgroundClass: string;
  title: string;
}

const HealthAreasSection: React.FC<Props> = ({ backgroundClass, title }) => {
  const [rotationTimeout, setRotationTimeout] = useState<NodeJS.Timeout|null>(null);
  const [activeIdx, setActiveIdx] = useState(0)
  const [firstIdx, setFirstIdx] = useState(0)

  const clickHA = (idx: number) => {
    rotationTimeout && clearTimeout(rotationTimeout)
    setActiveIdx(idx);
  }
  const windowForward = () => {
    rotationTimeout && clearTimeout(rotationTimeout)
    setFirstIdx(mod(firstIdx+1, healthAreas.length));
  }
  const windowBack = () => {
    rotationTimeout && clearTimeout(rotationTimeout)
    setFirstIdx(mod(firstIdx-1, healthAreas.length));
  }
  const MoveForward = () => {
    setActiveIdx(mod(activeIdx+1, healthAreas.length));
    setFirstIdx(mod(activeIdx+1, healthAreas.length));
  }

  useEffect(() => {
    rotationTimeout && clearTimeout(rotationTimeout)
    const timeout = setTimeout(MoveForward, 15*1000);
    setRotationTimeout(timeout)
  }, [activeIdx])

  return <>
    <div className={`${backgroundClass} py-12`}>
      <LimitMaxWidth>
        <SectionHeading>
          {title}
        </SectionHeading>
        <div className="mt-8 flex justify-between max-sm:w-screen max-sm:-ml-8">
          <button className="max-sm:hidden" onClick={windowBack}><CaretLeftLg/></button>
          <div className="flex sm:justify-center max-sm:justify-left sm:w-11/12 mx-auto overflow-x-auto overscroll-none no-scrollbar">
            {healthAreas.map((healthArea, idx) => <>
              <button
                className={`
                  ml-4
                  my-1
                  flex-none
                  rounded-md
                  w-40
                  h-11
                  text-subsb
                  font-nun
                  ${haOrdering[mod(idx-firstIdx, healthAreas.length)]}
                  ${activeIdx == idx ? "bg-syr-snd text-white" : "border-solid border-2 border-black text-black"}
                  ${mod(idx-firstIdx, healthAreas.length) >= 6 && "lg:hidden"}
                  ${mod(idx-firstIdx, healthAreas.length) >= 4 && "md:max-lg:hidden"}
                  ${mod(idx-firstIdx, healthAreas.length) >= 3 && "sm:max-md:hidden"}
                `}
                onClick={() => clickHA(idx)}
              >
                {healthArea.button}
              </button>
            </>
            )}
          </div>
          <button className="max-sm:hidden" onClick={windowForward}><CaretRightLg/></button>
        </div>
        <div className="sm:flex sm:justify-center mt-8">
          <div className="md:w-1/2 sm:max-md:w-7/12 flex justify-center sm:justify-end items-center">
            <picture className="max-sm:w-9/12">
              <source srcSet={healthAreas[activeIdx].avif} type="image/avif" />
              <source srcSet={healthAreas[activeIdx].webp} type="image/webp" />
              <img  src={healthAreas[activeIdx].png} alt={healthAreas[activeIdx].button}/>
            </picture>
          </div>
          <div className="p-8 md:w-1/2 sm:max-md:w-5/12 flex flex-col justify-center">
            <div className="md:-ml-24 sm:max-md:-ml-32 sm:mt-16 lg:w-128 md:max-lg:w-104 max-md:w-80 bg-syr-main rounded-md">
              <div className="flex items-center space-x-4 p-6">
                <div className="max-sm:text-m-t1 md:text-5xl sm:max-md:text-4xl font-dm text-white">
                  {healthAreas[activeIdx].stat}
                </div>
                <div className="max-sm:text-m-c sm:text-xl font-nun text-white">
                  {healthAreas[activeIdx].statDescription}
                </div>
              </div>
            </div>
            <h2 className="max-sm:text-m-lg-t sm:text-h3 font-dm">
              {healthAreas[activeIdx].title}
            </h2>
            {healthAreas[activeIdx].subTitle && 
              <h3 className="text-s3 font-nunsb">
                {healthAreas[activeIdx].subTitle}
              </h3>
            }
            {healthAreas[activeIdx].text.map(p => (
              <p className="max-sm:text-m-b sm:text-b1 font-nun">
                {p}
              </p>
            ))}
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

export default HealthAreasSection;
