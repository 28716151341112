import React from "react";

const PersonIcon: React.FC = () => <>
  <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.515625" width="45.4964" height="45.4964" rx="6" fill="white"/>
  <g clip-path="url(#clip0_3864_27831)">
  <path d="M23.3352 22.8196C24.6078 22.8196 25.8283 22.314 26.7282 21.4141C27.6281 20.5142 28.1336 19.2937 28.1336 18.0211C28.1336 16.7485 27.6281 15.528 26.7282 14.6281C25.8283 13.7282 24.6078 13.2227 23.3352 13.2227C22.0626 13.2227 20.8421 13.7282 19.9422 14.6281C19.0423 15.528 18.5367 16.7485 18.5367 18.0211C18.5367 19.2937 19.0423 20.5142 19.9422 21.4141C20.8421 22.314 22.0626 22.8196 23.3352 22.8196ZM26.5342 18.0211C26.5342 18.8695 26.1971 19.6832 25.5972 20.2831C24.9973 20.883 24.1836 21.2201 23.3352 21.2201C22.4868 21.2201 21.6731 20.883 21.0732 20.2831C20.4732 19.6832 20.1362 18.8695 20.1362 18.0211C20.1362 17.1727 20.4732 16.359 21.0732 15.7591C21.6731 15.1592 22.4868 14.8221 23.3352 14.8221C24.1836 14.8221 24.9973 15.1592 25.5972 15.7591C26.1971 16.359 26.5342 17.1727 26.5342 18.0211ZM32.9321 30.817C32.9321 32.4165 31.3326 32.4165 31.3326 32.4165H15.3378C15.3378 32.4165 13.7383 32.4165 13.7383 30.817C13.7383 29.2175 15.3378 24.419 23.3352 24.419C31.3326 24.419 32.9321 29.2175 32.9321 30.817ZM31.3326 30.8106C31.331 30.4171 31.0863 29.2335 30.0018 28.149C28.959 27.1062 26.9964 26.0185 23.3352 26.0185C19.6724 26.0185 17.7114 27.1062 16.6685 28.149C15.5841 29.2335 15.341 30.4171 15.3378 30.8106H31.3326Z" fill="url(#paint0_linear_3864_27831)"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_3864_27831" x1="13.7383" y1="13.2227" x2="36.641" y2="20.1442" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="0.0001" stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  <clipPath id="clip0_3864_27831">
  <rect width="25.5917" height="25.5917" fill="white" transform="translate(13.3125 12.7969)"/>
  </clipPath>
  </defs>
  </svg>
</>

export default PersonIcon;
